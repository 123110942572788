import { Icon } from '@blueprintjs/core'
import { getAuth, signOut } from 'firebase/auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Clickable } from './Clickable'

export interface ErrorScreenProps {
  message: string
}

export const ErrorScreen: React.FC<ErrorScreenProps> = React.memo(
  ({ message }: ErrorScreenProps) => {
    const router = useRouter()
    const [user] = useAuthState(getAuth())
    return (
      <div className="flex h-screen flex-col items-center justify-center text-center">
        <Icon icon="error" className="fill-current text-orange-500" size={26} />
        <h1 className="mt-2 mb-1 text-2xl font-semibold">
          Oops, something went wrong
        </h1>
        {message && (
          <div className="text-mono mt-5 max-w-3xl rounded bg-slate-200 p-3 text-slate-700">
            {message}
          </div>
        )}
        <p className={`mt-6 ${user ? 'max-w-lg' : 'max-w-sm'} px-4`}>
          {user ? (
            <>
              You can go back to{' '}
              <Link href="/">
                <a className="text-blue-500 underline">your workspace</a>
              </Link>{' '}
              or{' '}
              <Clickable
                onPress={() => {
                  void signOut(getAuth())
                  void router.replace('/')
                }}
              >
                <span className="text-blue-500 underline">log out</span>
              </Clickable>
              . If that&apos;s not what you&apos;re looking for
            </>
          ) : null}
          {user ? '' : "If you're certain you're using the right link"}, please
          try again or tweet{' '}
          <a
            target="_blank"
            className="text-blue-500 underline"
            href="//twitter.com/jeroenmakes"
            rel="noreferrer"
          >
            @jeroenmakes
          </a>{' '}
          to fix this.
        </p>
      </div>
    )
  }
)

ErrorScreen.displayName = 'ErrorScreen'
