import React from 'react'

export interface ClickableProps {
  children: React.ReactElement
  onPress: ((e: React.MouseEvent | React.KeyboardEvent) => void) | undefined
}

export const Clickable: React.FC<ClickableProps> = React.memo(
  ({ children, onPress }: ClickableProps) => {
    return React.cloneElement(children, {
      role: onPress ? 'button' : undefined,
      tabIndex: onPress ? 0 : undefined,
      onClick: onPress,
      onKeyDown: onPress,
    })
  }
)

Clickable.displayName = 'Clickable'
