import { Button, Icon } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { TextLoop } from 'react-text-loop-next'

type MobileOS = 'iOS' | 'Android'

interface SplashIfMobileProps {
  children: React.ReactElement
}

const SplashIfMobile: React.FC<SplashIfMobileProps> = React.memo(
  (props: SplashIfMobileProps) => {
    const [mobileOS, setMobileOS] = useState<MobileOS | undefined>(undefined)

    // Temporary note: the Android/iOS detection is not necessary for now since
    // both users will see the same screen - this will change in the future.

    // Detect if the user is on an iOS or Android device based on the user agent.
    // This only runs once, on the first render.
    useEffect(() => {
      const userAgent: string = window.navigator.userAgent
      if (/android/i.test(userAgent)) {
        setMobileOS('Android')
      } else if (/iPhone|iPod/.test(userAgent)) {
        // iPhones and iPods always use this capitalization in their user agents, so we won't need
        // to check for lowercase. iPad user agents also contain 'iPhone' - but we don't want them
        // to see the splash screen, so we exclude them here.
        if (!userAgent.includes('iPad')) {
          setMobileOS('iOS')
        }
      }
    }, [])

    return mobileOS ? (
      <div className="fixed flex-col items-center justify-center py-10 md:h-screen lg:static lg:flex lg:py-0">
        <div className="relative w-full max-w-screen-xl px-5">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/jeroenmakes/status/1530842623715549184"
            className="mb-3 inline-block bg-orange-100 px-1.5 py-0.5 text-xs font-medium text-orange-500 hover:text-orange-500"
          >
            V0.2 alpha
          </a>
          <h1 className="text-4xl font-medium tracking-tight text-slate-900 lg:max-w-md lg:text-6xl lg:tracking-tighter xl:max-w-none">
            Document your{' '}
            <span className="block lg:inline">
              <TextLoop>
                <div className="text-purple-500">database</div>
                <div className="text-cyan-500">API</div>
                <div className="text-sky-500">proposals</div>
                <div className="text-orange-500">types</div>
                <div className="text-rose-500">events</div>
              </TextLoop>
            </span>
          </h1>
          <div className="mt-4 text-xl text-slate-500 lg:text-2xl">
            Create sharable docs, free and super simple.
          </div>
          <div className="mt-4 border-l-2 border-orange-500 pl-4 text-base font-medium lg:hidden">
            WhatFields will support mobile soon, but doesn't right now. Sorry!
            It works well on your laptop though!
          </div>
          <a
            className="mt-6 inline-block"
            href="https://www.producthunt.com/posts/whatfields?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-whatfields"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=348293&theme=light"
              alt="WhatFields - Create&#0032;sharable&#0032;docs&#0044;&#0032;free&#0032;and&#0032;super&#0032;simple | Product Hunt"
              style={{ width: 250, height: 54 }}
              width="250"
              height="54"
            />
          </a>
        </div>
        <img src="/mobile.png" className="lg:hidden" />

        <div className="pointer-events-none hidden lg:block">
          <div className="fixed -right-56 top-24 h-screen w-[600px] -rotate-12 transform bg-purple-500" />
          <div className="fixed -right-24 top-40 h-screen w-[420px] -rotate-[4deg] transform border border-purple-700 bg-white p-10 shadow-2xl">
            <div className="mb-6 rounded-full border p-2 px-4 font-medium text-slate-400">
              whatfields.com/whatapi
            </div>
            <div className="flex items-center space-x-4">
              <img
                alt="WhatFields logo"
                src="/favicons/android-chrome-192x192.png"
                height={76}
                width={76}
              />
              <h1 className="text-3xl">What API</h1>
            </div>
            <div className="mt-4 space-x-1 border-t border-b py-2">
              <Button text="Folder" icon="folder-new" minimal />
              <Button text="Page" icon="add" minimal />
            </div>
            <div className="mt-6 space-y-3">
              <div className="flex h-10 w-44 items-center space-x-3 rounded-full bg-slate-100 px-6 font-medium text-slate-500">
                <Icon icon="folder-open" className="text-slate-400" />
                <div>Endpoints</div>
              </div>
              <div className="ml-6 flex h-10 w-52 items-center space-x-3 rounded-full bg-orange-100 px-6 text-rose-500">
                <Icon icon="document" className="text-rose-400" />
                <div>Getting started</div>
              </div>
              <div className="ml-6 flex h-10 w-48 items-center space-x-3 rounded-full bg-orange-100 px-6 text-rose-500">
                <Icon icon="document" className="text-rose-400" />
                <div>Authentication</div>
              </div>
              <div className="ml-6 flex h-10 w-44 items-center space-x-3 rounded-full bg-orange-100 px-6 text-rose-500">
                <Icon icon="document" className="text-rose-400" />
                <div>Example call</div>
              </div>

              <div className="flex h-10 w-48 items-center space-x-3 rounded-full bg-slate-100 px-6 font-medium text-slate-500">
                <Icon icon="folder-close" className="text-slate-400" />
                <div>Object types</div>
              </div>
              <div className="flex h-10 w-56 items-center space-x-3 rounded-full bg-slate-100 px-6 font-medium text-slate-500">
                <Icon icon="folder-close" className="text-slate-400" />
                <div>Analytics events</div>
              </div>
              <div className="flex h-10 w-44 items-center space-x-3 rounded-full bg-slate-100 px-6 font-medium text-slate-500">
                <Icon icon="folder-close" className="text-slate-400" />
                <div>Database</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      props.children
    )
  }
)

SplashIfMobile.displayName = 'SplashIfMobile'
export default SplashIfMobile
