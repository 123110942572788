import '../styles.css'
import type { AppProps } from 'next/app'
import { FirebaseApp, getApp, initializeApp } from 'firebase/app'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore,
} from 'firebase/firestore'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth'
import {
  connectFunctionsEmulator,
  Functions,
  getFunctions,
} from 'firebase/functions'
import { useState } from 'react'
import Head from 'next/head'
import { FocusStyleManager } from '@blueprintjs/core'
import Script from 'next/script'
import { ErrorBoundary } from '@sentry/nextjs'
import SplashIfMobile from '../pageComponents/SplashIfMobile'
import { ErrorScreen } from '../pageComponents/ErrorScreen'

FocusStyleManager.onlyShowFocusOnTabs()

let app: FirebaseApp
// eslint-disable-next-line import/no-mutable-exports
let db: Firestore
let auth: Auth
let fn: Functions
try {
  app = getApp()
  db = getFirestore(app)
  auth = getAuth(app)
  fn = getFunctions(app)
} catch {
  const isLocal =
    typeof window !== 'undefined' && location.hostname === 'localhost'
  app = initializeApp(
    isLocal
      ? {
          apiKey: '-------',
          authDomain: 'demo-docs.firebaseapp.com',
          projectId: 'demo-docs',
        }
      : {
          apiKey: 'AIzaSyDfUKX5Q0yFJApmUL9wd_UCdbSyl30inRc',
          authDomain: 'lbc-docs.firebaseapp.com',
          projectId: 'lbc-docs',
          storageBucket: 'lbc-docs.appspot.com',
          messagingSenderId: '552670263265',
          appId: '1:552670263265:web:ec55a4555dfbeea2ff44a0',
        }
  )
  db = getFirestore(app)
  auth = getAuth(app)
  fn = getFunctions(app)
  if (isLocal) {
    connectAuthEmulator(auth, 'http://localhost:9099')
    connectFirestoreEmulator(db, 'localhost', 8080)
    connectFunctionsEmulator(fn, 'localhost', 5001)
  }
}

function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          staleTime: 1000 * 60 * 5, // 5 minutes
        },
      },
    })
  )
  return (
    <ErrorBoundary
      fallback={({ error }) => <ErrorScreen message={error.message} />}
    >
      <SplashIfMobile>
        <QueryClientProvider client={queryClient}>
          <Head>
            <title>WhatFields</title>
            <meta
              property="description"
              content="Create sharable docs, free and super simple."
            />
            <meta
              property="og:description"
              content="Create sharable docs, free and super simple."
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/favicons/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicons/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicons/favicon-16x16.png"
            />
            <link rel="manifest" href="/favicons/site.webmanifest" />
            <link
              rel="mask-icon"
              href="/favicons/safari-pinned-tab.svg"
              color="#5bbad5"
            />
            <link rel="shortcut icon" href="/favicons/favicon.ico" />
            <meta name="apple-mobile-web-app-title" content="WhatFields" />
            <meta name="application-name" content="WhatFields" />
            <meta name="msapplication-TileColor" content="#00aba9" />
            <meta
              name="msapplication-config"
              content="/favicons/browserconfig.xml"
            />
            <meta name="theme-color" content="#ffffff" />
            <meta property="og:image:type" content="image/png" />
            <meta
              property="og:image"
              content="https://whatfields.com/og-image.png"
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
          </Head>
          <Component {...pageProps} />
          <Script src="https://scripts.simpleanalyticscdn.com/latest.js" />
          <noscript>
            <img
              src="https://queue.simpleanalyticscdn.com/noscript.gif"
              alt=""
              referrerPolicy="no-referrer-when-downgrade"
            />
          </noscript>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SplashIfMobile>
    </ErrorBoundary>
  )
}

export default App
export { db, fn }
